export const formatDate = (secs) => {
  const t = new Date(secs),
    year = t.getFullYear();
  let month = t.getMonth() + 1,
    date = t.getDate();
  month < 10 && (month = "0" + month);
  date < 10 && (date = "0" + date);
  return year + "-" + month + "-" + date;
};

export const getNowTime = () => {
  const myDate = new Date(),
    year = myDate.getFullYear(),
    month = myDate.getMonth() + 1 < 10 ? `0${myDate.getMonth() + 1}` : myDate.getMonth() + 1,
    date = myDate.getDate() < 10 ? `0${myDate.getDate()}` : myDate.getDate(),
    hours = myDate.getHours() < 10 ? `0${myDate.getHours()}` : myDate.getHours(),
    minutes = myDate.getMinutes() < 10 ? `0${myDate.getMinutes()}` : myDate.getMinutes(),
    seconds = myDate.getSeconds() < 10 ? `0${myDate.getSeconds()}` : myDate.getSeconds();
  return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
};

export const formatNum = {
  filters: {
    formatNum(value) {
      return (parseFloat(value).toFixed(2) + "").replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
    }
  }
};

export const isDate = (dateString) => {
  if (!dateString || dateString.trim() == "") {
    return true;
  }
  const r = dateString.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
  if (r == null) {
    return false;
  }
  const d = new Date(r[1], r[3] - 1, r[4]);
  const num = (d.getFullYear() == r[1] && (d.getMonth() + 1) == r[3] && d.getDate() == r[4]);
  if (num == 0) {
    return false;
  }
  return (num != 0);
};
