import axios from "@/axios/axiosConfig";

export const countSellerAmount = (params) => {
  return axios({
    url: "/instrumentSales/countSellerAmount",
    method: "post",
    data: params
  });
};

export const countGoodsAmount = (params) => {
  return axios({
    url: "/instrumentSales/countGoodsAmount",
    method: "post",
    data: params
  });
};

export const countMedicine = (params) => {
  return axios({
    url: "/medicineSales/countMedicine",
    method: "post",
    data: params
  });
};

export const countRange = (params) => {
  return axios({
    url: "/medicineSales/countRange",
    method: "post",
    data: params
  });
};

export const countDetail = (params) => {
  return axios({
    url: "/medicineSales/countDetail",
    method: "post",
    data: params
  });
};

export const countInstrumentSalesFlow = (params) => {
  return axios({
    url: "/instrumentSalesFlow/countInstrumentSalesFlow",
    method: "post",
    data: params
  });
};

export const countRangeFlow = (params) => {
  return axios({
    url: "/instrumentSalesFlow/countRange",
    method: "post",
    data: params
  });
};

export const countDetailFlow = (params) => {
  return axios({
    url: "/instrumentSalesFlow/countDetail",
    method: "post",
    data: params
  });
};

export const getInstrumentPerformance = (params) => {
  return axios({
    url: "/performance/getInstrumentPerformance",
    method: "post",
    data: params
  });
};

export const statisticsActivity = (params) => {
  return axios({
    url: "/visit-record/statistics-activity",
    method: "post",
    data: params
  });
};

export const statisticsSalesman = (params) => {
  return axios({
    url: "/visit-record/statistics-salesman",
    method: "post",
    data: params
  });
};

export const statisticsCustomer = (params) => {
  return axios({
    url: "/visit-record/statistics-customer",
    method: "post",
    data: params
  });
};

export const getFilterList = () => {
  return axios({
    url: "/performance/getFilterList",
    method: "get"
  });
};

export const getMedicinePerformance = (params) => {
  return axios({
    url: "/performance/getMedicinePerformance",
    method: "post",
    data: params
  });
};
