import { render, staticRenderFns } from "./scrollView.vue?vue&type=template&id=2ab4a0b8&scoped=true&"
import script from "./scrollView.vue?vue&type=script&lang=js&"
export * from "./scrollView.vue?vue&type=script&lang=js&"
import style0 from "./scrollView.vue?vue&type=style&index=0&id=2ab4a0b8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ab4a0b8",
  null
  
)

export default component.exports