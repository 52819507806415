<template>
  <div class="app-box">
    <x-screen-tab :bindYear="selectObject.year" :bindMonth="selectObject.month||0" @change="screenChange"></x-screen-tab>
    <div v-if="xhrData" class="count-title-box">总计(元)：{{xhrData.totalAmount|formatNum}}</div>
    <x-scroll-view :top="1">
      <table v-if="xhrData&&xhrData.items.length>0" class="data-table double-table">
        <colgroup>
          <col width="50%">
          <col width="50%">
        </colgroup>
        <thead>
          <tr>
            <th>
              <span>客户名称</span>
            </th>
            <th scope="col">
              <span class="table-sort-icon" :class="[sort?'table-sort-down-icon':'table-sort-up-icon']" @click="sortEvent">总金额(元)</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in xhrData.items" :key="index">
            <th scope="row">{{item.itemName}}</th>
            <td>{{item.amount|formatNum}}</td>
          </tr>
        </tbody>
      </table>
    </x-scroll-view>
  </div>
</template>

<script>
import scrollView from "@/components/scrollView";
import screenTab from "@/components/screenTab";
import { countGoodsAmount } from "@/api/reportCenter";
import noDataBg from "@/components/noDataBg";
import { formatNum } from "@/script/tools";

export default {
  data () {
    return {
      sort: true,
      xhrData: null,
      ajaxEnd: false,
      selectObject: {
        level1Item: this.$route.params.item,
        level2Item: this.$route.params.towItem,
        year: this.$route.params.year,
        month: parseInt(this.$route.params.month) || null,
        queryTypeValue: this.$route.params.typeValue
      }
    };
  },
  mixins: [formatNum],
  created () {
    document.title = `${this.$route.params.item}${this.selectObject.level2Item}`;
    this.getDataList();
  },
  methods: {
    sortEvent () {
      this.sort = !this.sort;
      this.xhrData.items.reverse();
    },
    getDataList () {
      countGoodsAmount(this.selectObject).then(xhr => {
        this.xhrData = xhr.data;
        this.sort = true;
        this.ajaxEnd = true;
      });
    },
    screenChange (data) {
      this.selectObject.year = data.year.code;
      this.selectObject.month = data.month.code || null;
      this.getDataList();
    }
  },
  watch: {
    $route (to, from) {
      if (to.meta.index <= from.meta.index) {
        document.title = `${this.$route.params.item}${this.selectObject.level2Item}`;
      }
    }
  },
  components: {
    [scrollView.name]: scrollView,
    [screenTab.name]: screenTab,
    [noDataBg.name]: noDataBg
  }
};
</script>

<style scoped>
</style>
