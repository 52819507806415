<template>
  <div class="screen-box">
    <span v-if="slotsArea.length>0&&checkAreaItem" @click="popupAreaShow=true;">
      <i>{{checkAreaItem.name}}</i>
    </span>
    <span @click="popupDateShow=true;">
      <i v-if="checkYearItem">{{checkYearItem.name}}{{checkMonthItem.name}}</i>
    </span>
    <mt-popup v-model="popupAreaShow" position="bottom">
      <div class="popup-box">
        <div class="popup-header" @touchmove.prevent>
          <button @click="popupAreaShow=false;">取消</button>
          <button @click="finishAreaEvent">完成</button>
        </div>
        <mt-picker :slots="slotsArea" value-key="name" @change="onValuesAreaChange" @touchmove.native.stop.prevent></mt-picker>
      </div>
    </mt-popup>
    <mt-popup v-model="popupDateShow" position="bottom">
      <div class="popup-box">
        <div class="popup-header" @touchmove.prevent>
          <button @click="popupDateShow=false;">取消</button>
          <button @click="finishDateEvent">完成</button>
        </div>
        <mt-picker :slots="slotsDate" value-key="name" @change="onValuesDateChange" @touchmove.native.stop.prevent></mt-picker>
      </div>
    </mt-popup>
  </div>
</template>

<script>
import { Popup, Picker } from "mint-ui";

export default {
  name: "x-screen-tab",
  data () {
    return {
      popupAreaShow: false,
      popupDateShow: false,
      checkAreaItem: null,
      checkAreaItemTemp: null,
      checkYearItem: null,
      checkYearItemTemp: null,
      checkMonthItem: null,
      checkMonthItemTemp: null,
      regionInit: false,
      slotsDate: [{
        flex: 1,
        values: [],
        textAlign: "center",
        defaultIndex: 0
      }, {
        flex: 1,
        values: [{
          code: null,
          name: null
        }],
        textAlign: "center"
      }]
    };
  },
  props: {
    slotsArea: {
      type: Array,
      default: () => []
    },
    bindArea: {
      type: [Number, String],
      default: null
    },
    bindYear: {
      type: [Number, String],
      default: null
    },
    bindMonth: {
      type: [Number, String],
      default: null
    }
  },
  created () {
    setTimeout(() => {
      this.getYear();
      this.setCheckArea();
    }, 0);
  },
  methods: {
    getYear () {
      const nowTime = new Date(),
        nowYear = nowTime.getFullYear();
      for (let i = 2020; i <= nowYear; i++) {
        this.slotsDate[0].values.push({
          code: i,
          name: i + "年"
        });
      }
    },
    getMonth (year) {
      const nowTime = new Date(),
        nowYear = nowTime.getFullYear(),
        nowMonth = nowTime.getMonth() + 1,
        arr = [{
          code: 0,
          name: "全部"
        }];
      const jMin = year == 2020 ? 11 : 1;
      const jMax = year == nowYear ? nowMonth : 12;
      for (let j = jMax; j >= jMin; j--) {
        arr.push({
          code: j,
          name: j + "月"
        });
      }
      return arr;
    },
    onValuesAreaChange (picker, values) {
      this.checkAreaItemTemp = values;
    },
    finishAreaEvent () {
      this.popupAreaShow = false;
      this.checkAreaItem = this.checkAreaItemTemp[0];
      this.$emit("change", {
        "area": this.checkAreaItem,
        "year": this.checkYearItem,
        "month": this.checkMonthItem
      });
    },
    onValuesDateChange (picker, values) {
      if (this.regionInit) {
        this.checkYearItemTemp = values[0];
        this.checkMonthItemTemp = values[1];
        picker.setSlotValues(1, this.getMonth(values[0].code));
      } else {
        this.regionInit = true;
      }
    },
    finishDateEvent () {
      this.popupDateShow = false;
      this.checkYearItem = this.checkYearItemTemp;
      this.checkMonthItem = this.checkMonthItemTemp;
      this.$emit("change", {
        "area": this.checkAreaItem,
        "year": this.checkYearItem,
        "month": this.checkMonthItem
      });
    },
    setCheckArea () {
      if (this.slotsArea.length > 0) {
        this.slotsArea[0].values.map((item, index) => {
          if (this.bindArea == item.code) {
            this.checkAreaItemTemp = [item];
            this.checkAreaItem = item;
            this.slotsArea[0].defaultIndex = index;
          }
        });
      }
      this.slotsDate[0].values.map((item, index) => {
        if (this.bindYear == item.code) {
          this.checkYearItemTemp = [item];
          this.checkYearItem = item;
          this.slotsDate[0].defaultIndex = index;
        }
      });
      this.slotsDate[1].values = this.getMonth(this.bindYear);
      this.slotsDate[1].values.map((item, index) => {
        if (this.bindMonth == item.code) {
          this.checkMonthItemTemp = [item];
          this.checkMonthItem = item;
          this.slotsDate[1].defaultIndex = index;
        }
      });
    }
  },
  components: {
    [Popup.name]: Popup,
    [Picker.name]: Picker
  }
};
</script>

<style scoped>
.screen-box {
  height: 0.52rem;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
}
.screen-box span {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.screen-box span i {
  position: relative;
  line-height: 1;
  font-size: 0.15rem;
  color: #30353d;
  padding-right: 0.14rem;
}
.screen-box span i::before {
  position: absolute;
  top: 0.01rem;
  right: 0;
  content: '';
  width: 0.06rem;
  height: 0.06rem;
  transform: rotate(45deg);
  border-right: solid 0.01rem #99a0aa;
  border-bottom: solid 0.01rem #99a0aa;
}
</style>
