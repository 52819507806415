<template>
  <div ref="scrollBox" class="scroll-box" :style="{top:top+'rem',bottom:bottom+'rem','background-color':bg}" @scroll="onScroll($event);">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "x-scroll-view",
  data () {
    return {};
  },
  props: {
    top: {
      type: Number,
      default: 0
    },
    bottom: {
      type: Number,
      default: 0
    },
    bg: {
      type: String,
      default: "#ffffff"
    }
  },
  methods: {
    setScrollTopPos (num) {
      this.$refs.scrollBox.scrollTop = num;
    },
    onScroll (event) {
      const offsetHeight = event.currentTarget.offsetHeight,
        scrollHeight = event.target.scrollHeight,
        scrollTop = event.target.scrollTop,
        scrollBottom = offsetHeight + scrollTop;
      this.$emit("scrollBarPos", scrollTop);
      if (scrollBottom === scrollHeight || scrollBottom >= scrollHeight - 10) {
        this.$emit("scrollLoadData", event);
      }
    }
  }
};
</script>

<style scoped>
.scroll-box {
  position: absolute;
  width: 100%;
  overflow: auto;
}
</style>
